<script>
//import Multiselect from "vue-multiselect";
import {required, email} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import "vue-multiselect/dist/vue-multiselect.min.css";
import ClienteService from "@/services/ClienteService";
const itemService = new ClienteService();

import ZonaService from "@/services/ZonaService";
const relationService = new ZonaService();


import InfoModel from "@/classes/cliente";
const infoModel = new InfoModel();


import RelationModel from "@/classes/direccion";
const relationModel = new RelationModel();

import HelperJS from "@/functions/general";
const helper = new HelperJS();

/**
 * Add-product component
 */
const nameSeccion = 'Cliente';
//const namePlural = nameSeccion+'s';
const routeParentName = 'clientes';

export default {
  page: {
    title: nameSeccion,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: nameSeccion,
      actionCreate: true,
      info_page:{       
        routeParentName: null,
        routeBack: null,
        nameSeccion: null,
      },
      objectData:{},
      formRelation: {},
      newDirection: false,
      zonas: [],
      items: [
        {
          text: null,
        },
        {
          text: null,
          active: true,
        },
      ],
      submitted: false,

      
    };
  },
  validations: {
      objectData: {
        name: {
          required,
        },
        last_name: {
          required,
        },
        email:{
          required,
          email
        }
      },
    },
  middleware: "authentication",
  
  created(){

    this.initPage();
  },
  
  methods: {
    async initPage(){
        
        await this.aditionalServices();
        this.info_page.routeParentName = routeParentName;
        this.info_page.nameSeccion = nameSeccion;
        this.info_page.routeBack = '/'+routeParentName;
        let text_page = 'Agregar '
        
        this.objectData = JSON.parse(JSON.stringify(infoModel.fillable));
        if(this.$route.name===nameSeccion+'Edit'){          
          this.actionCreate = false;
          text_page = 'Editar';

          itemService.getById(this.$route.params.id).then(async response=>{  
            let data = response.data.object_data;
            this.objectData =   JSON.parse(JSON.stringify(new InfoModel(data)));
          })
        }else{
          //this.objectData.direcciones.push(JSON.parse(JSON.stringify(relationModel.fillable)))
        }
        this.items[0].text= nameSeccion
        this.items[1].text= text_page+' '+nameSeccion;
      },
      
      async aditionalServices(){
        let response = await relationService.getList();
        response =  response.data.object_data;
        this.zonas = response;
      },
      async updateForm(){
      
          let formData = JSON.parse(JSON.stringify(this.objectData));

          if(this.newDirection){
            formData.direccionNueva = JSON.parse(JSON.stringify(this.formRelation))
          }
          if(this.actionCreate){
            delete formData.id;
            await itemService.create(formData)
          }else{
            await itemService.update(formData)
          }
         this.successForm(this.info_page.routeBack);
      
      },

      successForm(route){
        Swal.fire("¡Operación exitosa!", "Información guardada con éxito", "success").then(() => {
        this.$router.push({
              path: route,
            });
        });
    },
    cancelForm(){
      this.$router.push({
        path:'/'+routeParentName,
      });
    },
    addDirection(){
          this.formRelation = JSON.parse(JSON.stringify(relationModel.fillable))

          this.newDirection = !this.newDirection;
    },
    activeDirection(dataInfo){
          this.objectData.direcciones.map(item=> item.default = false);
          dataInfo.default = true;
          this.formRelation = JSON.parse(JSON.stringify(dataInfo))
          this.newDirection = true;

    },
    
    editDirection(dataInfo){
          this.formRelation = JSON.parse(JSON.stringify(dataInfo))

          this.newDirection = true;
    },
      
      validateForm(){

        this.submitted = true;
        if(this.$v.$error===false &&  this.$v.$invalid===false){
          this.updateForm();
        }
        
      this.$v.$touch();
      },
      slugify(stringValue){

        return helper.convertToSlug(stringValue);
      },
     routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <div class="card">
          
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Información general</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completa todos los datos
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            

            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                <form>
                 
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="name">Nombre</label>
                        <input id="name" name="name" v-model="objectData.name" type="text" class="form-control"
                          :class="{
                            'is-invalid': submitted && $v.objectData.name.$error,
                          }"/>
                        <div v-if="submitted && $v.objectData.name.$error" class="invalid-feedback">
                          <span v-if="!$v.objectData.name.required">Campo requerido</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="last_name">Apellidos</label>
                        <input id="last_name" name="last_name" v-model="objectData.last_name" type="text" class="form-control"
                          :class="{
                            'is-invalid': submitted && $v.objectData.last_name.$error,
                          }"/>
                        <div v-if="submitted && $v.objectData.last_name.$error" class="invalid-feedback">
                          <span v-if="!$v.objectData.last_name.required">Campo requerido</span>
                        </div>
                      </div>
                    </div>
                
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                  
                  
                      <div class="mb-3">
                        <label>Correo electrónico</label>
                          <input
                            v-model="objectData.email"
                            type="email"
                            name="email"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.objectData.email.$error,
                            }"
                            placeholder="Ingresar Email"/>
                          <div v-if="submitted && $v.objectData.email.$error" class="invalid-feedback">
                            <span v-if="!$v.objectData.email.required"
                              >Campo requerido.</span>
                            <span v-if="!$v.objectData.email.email">Añadir un correo eléctronico válido.</span>
                          </div>
                      </div>
                    </div>
                    
                    <div class="col-md-6">
                      <div class="form-group mb-3">
                          <label>Teléfono</label>
                          <input v-model="objectData.phone" type="text" class="form-control"
                          :class="{
                            'is-invalid': submitted && $v.objectData.phone.$error,
                          }"/>
                        
                           <div v-if="submitted && $v.objectData.phone.$error" class="invalid-feedback">
                          <span v-if="!$v.objectData.phone.required">Campo requerido.</span>
                        </div>
                      </div>
                       
                    </div>
                  </div>
                
                </form>
              </div>
            </b-collapse>
          </div>


          <div class="card">
            <a
              href="javascript: void(0);"
              class="collapsed text-dark"
              data-toggle="collapse"
              v-b-toggle.checkout-shippinginfo-collapse
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <i class="uil uil-truck text-primary h2"></i>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Dirección de envío</h5>
                    <p class="text-muted text-truncate mb-0">
                     
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="checkout-shippinginfo-collapse">
              <div class="p-4 border-top">
                <h5 class="font-size-14 mb-3">Información dirección </h5>
                <div class="row">

                  <div class="col-lg-4 col-sm-6" v-for="direccion, key in objectData.direcciones" :key="key">
                    <div class="card border rounded shipping-address" :class="direccion.default?'active':''">
                      <div class="card-body">

                      

                        <a
                          class="float-end ms-1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                          @click="editDirection(direccion)"
                        >
                          <i class="uil uil-pen font-size-16"></i>
                        </a>
                        <a
                          href="#"
                          class="float-end ms-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Seleccionar default"
                          @click="activeDirection(direccion)"
                        >
                          <i class="mdi font-size-16" :class="direccion.default?' mdi-star':' mdi-star-outline'"></i>
                        </a>                  
                        <h5 class="font-size-14">{{ direccion.full_name }}</h5>
                        <p class="mb-1">
                          {{ direccion.street+' #'+ direccion.number+', '+direccion.colony+'. CP: '+direccion.zipcode+' '+direccion.zona_id}}

                        </p>
                        <span class="mb-0"><i class="uil uil-phone"></i> {{ direccion.phone }}</span>
                        <p class="mb-0">{{ direccion.message }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="card  rounded  shipping-address">
                      <div class="card-body text-center pt-5">
                        <a
                         
                          class="float-center ms-1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Agregar"
                          @click="addDirection()"
                        >
                       
                        <i class="uil uil-plus-circle font-size-24"></i>
                        <h5 class="font-size-14">Agregar dirección</h5>
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="newDirection">
                  <form>
                  <div>
                    <div class="row">
                      <!--<div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-name">Nombre del lugar</label>
                          <input v-model="formRelation.name" type="text"  class="form-control" id="billing-name"/>
                        </div>
                      </div>-->
                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-name">Nombre de quien recibe</label>
                          <input v-model="formRelation.full_name" type="text"  class="form-control" id="billing-name"/>
                        </div>
                      </div>
                      
                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-email-address">Correo electrónico</label>
                          <input v-model="formRelation.email" type="email"  class="form-control" id="billing-name"/>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-phone">Teléfono</label>
                          <input v-model="formRelation.phone" type="text"  class="form-control" id="billing-name"/>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group mb-4">
                          <label for="billing-name">Calle</label>
                          <input v-model="formRelation.street" type="text"  class="form-control" id="billing-name"/>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group mb-4">
                          <label for="billing-name">Número Int / Ext</label>
                          <input v-model="formRelation.number" type="text"  class="form-control" id="billing-name"/>
                        </div>
                      </div>
                      
                      <div class="col-lg-3">
                        <div class="form-group mb-4">
                          <label for="billing-email-address">Colonia</label>
                          <input v-model="formRelation.colony" type="text"  class="form-control" id="billing-name"/>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group mb-4">
                          <label for="billing-phone">Código Postal</label>
                          <input v-model="formRelation.zipcode" type="text"  class="form-control" id="billing-name"/>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group mb-4">
                          <label for="billing-name">Ciudad</label>
                        
                          <select
                            class="form-control custom-select"
                            title="Country"
                            v-model="formRelation.zona_id"
                          >
                            <option value="0">Seleccionar ciudad</option>
                            <option :key="idx" :value="zona.id" v-for="(zona, idx) in zonas">{{zona.name}}</option>
                                              
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-9">
                        <div class="form-group mb-4">
                          <label for="billing-address">Comentario</label>
                              <textarea
                              v-model="formRelation.comment"
                                class="form-control"
                                id="billing-address"
                                rows="3"
                              ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                </div>
              </div>
             
            </b-collapse>
          </div>

        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a  class="btn btn-danger"  @click.prevent="cancelForm()">
          <i class="uil uil-times me-1"></i> Cancelar
        </a>
        <a  class="btn btn-success ms-1"  @click.prevent="validateForm()">
          <i class="uil uil-file-alt me-1" ></i> Guardar cambios
        </a>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>
